import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import DefaultLayout from "@/layout/DefaultLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    meta: {
      name: "Home",
    },
    redirect: { name: "Inquiry" },
    component: DefaultLayout,
    children: [
      {
        path: "formularz-zapytania",
        name: "Inquiry",
        component: () =>
          import(
            /* webpackChunkName: "inquiry" */ "@/views/inquiry/InquiryView.vue"
          ),
      },
      {
        path: "umowa/:token",
        name: "Contract",
        component: () =>
          import(
            /* webpackChunkName: "contract" */ "@/views/contract/ContractView.vue"
          ),
      },
      {
        path: "uzupelniono-dane/:token",
        name: "Fill",
        component: () =>
          import(
            /* webpackChunkName: "contract" */ "@/views/contract/FillView.vue"
          ),
      },
      {
        path: "podpisywanie-umowy/:token",
        name: "Sign",
        component: () =>
          import(
            /* webpackChunkName: "contract" */ "@/views/contract/SignView.vue"
          ),
      },
      {
        path: "akceptacja-oferty/:token",
        name: "Accept",
        component: () =>
          import(
            /* webpackChunkName: "client" */ "@/views/client/AcceptView.vue"
          ),
      },
      {
        path: "oczekiwanie-na-mcs/:token",
        name: "Waits",
        component: () =>
          import(
            /* webpackChunkName: "client" */ "@/views/client/WaitsView.vue"
          ),
      },
      {
        path: "rezerwacja-terminu/:token",
        name: "Reservation",
        component: () =>
          import(
            /* webpackChunkName: "client" */ "@/views/client/ReservationView.vue"
          ),
      },
      {
        path: "umawianie-spotkania/:token",
        name: "Meeting",
        component: () =>
          import(
            /* webpackChunkName: "client" */ "@/views/client/MeetingView.vue"
          ),
      },
      {
        path: "zapis-na-powiadomienie/:token",
        name: "Interest",
        component: () =>
          import(
            /* webpackChunkName: "client" */ "@/views/client/InterestView.vue"
          ),
      },
      {
        path: "rezygnacja-z-oferty/:token",
        name: "Reject",
        component: () =>
          import(
            /* webpackChunkName: "client" */ "@/views/client/RejectView.vue"
          ),
      },
      {
        path: "platnosc/oplata-rezerwacyjna/:token",
        name: "PayBookingFee",
        component: () =>
          import(
            /* webpackChunkName: "pay" */ "@/views/pay/BookingFeeView.vue"
          ),
      },
      {
        path: "platnosc/opracowanie-scenariusza-slubu/:token",
        name: "PayScript",
        component: () =>
          import(/* webpackChunkName: "pay" */ "@/views/pay/ScriptView.vue"),
      },
      {
        path: "platnosc/prowadzenie-ceremonii/:token",
        name: "PayWedding",
        component: () =>
          import(/* webpackChunkName: "pay" */ "@/views/pay/WeddingView.vue"),
      },
      {
        path: "platnosc/sukces",
        name: "PaySuccess",
        component: () =>
          import(/* webpackChunkName: "pay" */ "@/views/pay/SuccessView.vue"),
      },
      {
        path: "platnosc/blad",
        name: "PayError",
        component: () =>
          import(/* webpackChunkName: "pay" */ "@/views/pay/ErrorView.vue"),
      },
    ],
  },
  {
    path: "/pl/kontakt",
    name: "Test",
    redirect: { name: "Inquiry" },
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/error/NotFoundView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
